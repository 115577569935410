import React, { useState, useEffect } from 'react';
import { Button, DropdownItemProps, Select } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';

import { FormattedMessage, useIntl } from 'react-intl';

import _ from 'lodash';

import {
  agentLoginService,
  getAgentPurchaserList,
} from 'services/dashboard/getAgentPurchaserList';
import SvgIcon from 'components/SvgIcon';
import { ICONS } from 'utils/global/globalConstants';

const AgentDashboard: React.FC = () => {
  const inlt = useIntl();
  const navigate = useNavigate();

  const [purchaserList, setPurchaserList] = useState<Array<DropdownItemProps>>(
    [],
  );
  const [isPurchaserListLoading, setIsLoadingPurchaserList] = useState(false);
  const [selectedContact, setSelectedContact] = useState('');

  useEffect(() => {
    getAgentPurchaserList(setPurchaserList, setIsLoadingPurchaserList);
  }, []);

  const agentLogin = () => {
    agentLoginService(selectedContact, navigate);
  };

  return (
    <div className="agent-dashboard-container">
      <div className="selected-user-data-card">
        <div className="text-section">
          <div className="title">
            <FormattedMessage
              id="agentDashboard.titile"
              defaultMessage="Selezionare l'utente con cui si vuole operare"
            />
          </div>
        </div>
        <Select
          options={purchaserList}
          loading={isPurchaserListLoading}
          placeholder={inlt.formatMessage({
            defaultMessage: 'Selezionare un utente',
            id: 'agent.placeholder.selectUser',
          })}
          onChange={(_e, data) =>
            setSelectedContact(_.isString(data.value) ? data.value : '')
          }
        />
        <div className="selected-user-data-card-row">
          <SvgIcon icon={ICONS.USER} height={25} width={25} />
          {!_.isEmpty(selectedContact)
            ? _.find(purchaserList, { value: selectedContact })?.text
            : '- - -'}
        </div>
        <div className="selected-user-data-card-row">
          <SvgIcon icon={ICONS.DOCUMENT} height={25} width={25} />
          {!_.isEmpty(selectedContact)
            ? _.find(purchaserList, { value: selectedContact })?.contact
                ?.vatNumber
            : '- - -'}
        </div>
        <div className="selected-user-data-card-row">
          <SvgIcon icon={ICONS.INFORMATION} height={25} width={25} />
          {!_.isEmpty(selectedContact)
            ? _.find(purchaserList, { value: selectedContact })?.contact?.city +
              ' - ' +
              _.find(purchaserList, { value: selectedContact })?.contact
                ?.address
            : '- - -'}
        </div>
        <Button
          className="main-button"
          content={inlt.formatMessage({
            defaultMessage: 'Esegui accesso',
            id: 'agent.button.accessButton',
          })}
          disabled={_.isEmpty(selectedContact)}
          onClick={() => agentLogin()}
          style={{ marginTop: '1rem' }}
        />
      </div>
    </div>
  );
};

export default AgentDashboard;
