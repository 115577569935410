import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';

import TabPaneHeader from 'components/PageHeader';
import { Button, Select } from 'semantic-ui-react';
import SvgIcon from 'components/SvgIcon';
import { ICONS } from 'utils/global/globalConstants';
import { useNavigate } from 'react-router-dom';
import {
  AdminLoginContactData,
  AdminLoginContactList,
  AllowedContactType,
} from './types';
import {
  adminContactLoginCall,
  adminContactLoginList,
} from 'services/admin/adminContactLogin';
import _ from 'lodash';
import { Checkbox } from '@material-ui/core';

const initialAgentLoginContactListObject = {
  completeList: [],
  customerList: [],
  supplierList: [],
};

const AdminContactLogin: React.FC = () => {
  const inlt = useIntl();
  const navigate = useNavigate();

  const [contactList, setContactList] = useState<AdminLoginContactList>(
    initialAgentLoginContactListObject,
  );
  const [completeContactCollection, setCompleteContactCollection] = useState<
    Array<AdminLoginContactData>
  >([]);
  const [isContactListLoading, setIsContactListLoading] = useState(false);
  const [selectedContact, setSelectedContact] = useState('');
  const [selectedContactType, setSelectedContactType] =
    useState<AllowedContactType>('supplier');
  const [isAdminLogginIn, setIsAdminLogginIn] = useState(false);

  useEffect(() => {
    adminContactLoginList(
      setContactList,
      setIsContactListLoading,
      setCompleteContactCollection,
    );
  }, []);

  const adminContactLogin = () => {
    adminContactLoginCall(selectedContact, setIsAdminLogginIn, navigate);
  };

  const getSelectedList = () => {
    if (selectedContactType === 'customer') {
      return contactList.customerList;
    }

    if (selectedContactType === 'complete') {
      return contactList.completeList;
    }

    return contactList.supplierList;
  };

  return (
    <>
      <Helmet>
        <body />
        <title>MSM - Impersonificazione</title>
        <meta name="description" content="Dashboard" />
      </Helmet>
      <TabPaneHeader
        title={inlt.formatMessage({
          defaultMessage: 'Impersonificazione utente',
          id: 'adminContactLogin.pageHeader.title',
        })}
        withBottomBorder
      />
      <div className="admin-contact-login-container">
        <div className="selected-user-data-card">
          <div className="text-section">
            <div className="title">
              <FormattedMessage
                id="agentDashboard.titile"
                defaultMessage="Selezionare l'utente con cui si vuole operare"
              />
            </div>
          </div>
          <div className="contact-type-switch">
            <div className="single-contact-type">
              <FormattedMessage
                id="adminContactLogin.list.switch.type.supplier"
                defaultMessage="Fornitore"
              />
              <Checkbox
                color="primary"
                checked={selectedContactType === 'supplier'}
                onClick={() => setSelectedContactType('supplier')}
              />
            </div>
            <div className="single-contact-type">
              <FormattedMessage
                id="adminContactLogin.list.switch.type.customer"
                defaultMessage="Cliente"
              />
              <Checkbox
                color="primary"
                checked={selectedContactType === 'customer'}
                onClick={() => setSelectedContactType('customer')}
              />
            </div>
            <div className="single-contact-type">
              <FormattedMessage
                id="adminContactLogin.list.switch.type.complete"
                defaultMessage="Tutti"
              />
              <Checkbox
                color="primary"
                checked={selectedContactType === 'complete'}
                onClick={() => setSelectedContactType('complete')}
              />
            </div>
          </div>
          <Select
            options={getSelectedList()}
            loading={isContactListLoading}
            placeholder={inlt.formatMessage({
              defaultMessage: 'Selezionare un utente',
              id: 'adminContactLogin.placeholder.selectUser',
            })}
            onChange={(_e, data) =>
              setSelectedContact(_.isString(data.value) ? data.value : '')
            }
            search
          />
          <div className="selected-user-data-card-row">
            <SvgIcon icon={ICONS.USER} height={25} width={25} />
            {!_.isEmpty(selectedContact)
              ? _.find(completeContactCollection, { value: selectedContact })
                  ?.text
              : '- - -'}
          </div>
          <div className="selected-user-data-card-row">
            <SvgIcon icon={ICONS.DOCUMENT} height={25} width={25} />
            {!_.isEmpty(selectedContact)
              ? _.find(completeContactCollection, { value: selectedContact })
                  ?.contact?.vatNumber
              : '- - -'}
          </div>
          <div className="selected-user-data-card-row">
            <SvgIcon icon={ICONS.INFORMATION} height={25} width={25} />
            {!_.isEmpty(selectedContact)
              ? _.find(completeContactCollection, { value: selectedContact })
                  ?.contact?.city +
                ' - ' +
                _.find(completeContactCollection, { value: selectedContact })
                  ?.contact?.address
              : '- - -'}
          </div>
          <Button
            className="main-button"
            content={inlt.formatMessage({
              defaultMessage: 'Esegui accesso',
              id: 'adminContactLogin.button.accessButton',
            })}
            disabled={_.isEmpty(selectedContact) || isAdminLogginIn}
            onClick={() => adminContactLogin()}
            style={{ marginTop: '1rem' }}
            loading={isAdminLogginIn}
          />
        </div>
      </div>
    </>
  );
};

export default AdminContactLogin;
