import React, { useEffect, useState } from 'react';
import { Grid, Image, Segment } from 'semantic-ui-react';

// Components
import Card from 'components/Card';
import EmptyReservations from 'components/EmptyReservations';

// Icons
import approvedReservationsIcon from 'assets/images/icons/approvedReservations.svg';
import arrow from 'assets/images/icons/arrow.svg';
import bookingCalendar from 'assets/images/icons/bookingCalendar.svg';
import calendarAdmin from 'assets/images/icons/calendarAdmin.svg';
import processedReservationsIcon from 'assets/images/icons/processedReservations.svg';

// Services
import { getDashboardAdmin } from 'services/admin/adminDashboard';
import { COLORS, FE_ROUTES, ICONS } from 'utils/global/globalConstants';
import { FormattedMessage, useIntl } from 'react-intl';
import { AdminDashboardData } from 'services/admin/types';
import { CircularProgress } from '@material-ui/core';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import SvgIcon from 'components/SvgIcon';

const initialDashboardData: AdminDashboardData = {
  approvedReservations: 0,
  currentDayReservations: [],
  reservationInProcess: 0,
  userRequestsToAccept: 0,
  userRequestsToApprove: 0,
};

const AdminDashboard: React.FC = () => {
  const [areDashboardDataLoading, setAreDashboardDataLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState(initialDashboardData);
  const intl = useIntl();
  const navigate = useNavigate();

  useEffect(() => {
    getDashboardAdmin(setAreDashboardDataLoading, setDashboardData);
  }, []);

  return (
    <div className="admin-dashboard">
      <Grid columns={2}>
        <Grid.Row stretched>
          <Grid.Column width={7}>
            <Grid columns={2}>
              <Grid.Row stretched>
                <Grid.Column>
                  <Card
                    iconSrc={approvedReservationsIcon}
                    rightIcon={arrow}
                    title={dashboardData.approvedReservations}
                    subTitle={intl.formatMessage({
                      id: 'admin.reservationApproved',
                      defaultMessage: 'Prenotazioni approvate',
                    })}
                    link={intl.formatMessage({
                      id: 'admin.linkToReservation',
                      defaultMessage: 'Vedi prenotazioni',
                    })}
                    linkTo={`${FE_ROUTES.RESERVATION_LIST}/approved`}
                    areDataLoading={areDashboardDataLoading}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Card
                    iconSrc={processedReservationsIcon}
                    rightIcon={arrow}
                    title={dashboardData.reservationInProcess}
                    subTitle={intl.formatMessage({
                      id: 'admin.reservationElaboration',
                      defaultMessage: 'Prentoazioni in elaborazione',
                    })}
                    link={intl.formatMessage({
                      id: 'admin.linkToReservation.two',
                      defaultMessage: 'Vedi prenotazioni',
                    })}
                    linkTo={`${FE_ROUTES.RESERVATION_LIST}/toApprove`}
                    areDataLoading={areDashboardDataLoading}
                  />
                </Grid.Column>
              </Grid.Row>
              {/*<Grid.Row stretched>
                                 <Grid.Column>
                  <Card
                    iconSrc={statusDocuments}
                    rightIcon={arrow}
                    title={dashboardData.userRequestsToAccept}
                    subTitle="Richieste nuovi utenti"
                    link="Vedi utenti da verificare"
                    linkTo={FE_ROUTES.NEW_USER_REQUESTS}
                    areDataLoading={areDashboardDataLoading}
                  />
                </Grid.Column>
                                 <Grid.Column>
                  <Card
                    iconSrc={openOrders}
                    rightIcon={arrow}
                    title={dashboardData.userRequestsToApprove}
                    subTitle="Richieste da approvare"
                    link="Vedi utenti da approvare"
                    linkTo={`${FE_ROUTES.NEW_USER_REQUESTS}/1`}
                    areDataLoading={areDashboardDataLoading}
                  />
                </Grid.Column> 
              </Grid.Row> */}
            </Grid>
          </Grid.Column>
          <Grid.Column width={9}>
            <Segment className="card-grid-header">
              <div className="calendar-header-dashboard">
                <Image src={bookingCalendar} />
                <span className="title-text">
                  <FormattedMessage
                    id="admin.dashboard.empty.reservations.title"
                    defaultMessage="Calendario prenotazioni giornaliere"
                  />
                </span>
              </div>
              {areDashboardDataLoading ? (
                <div className="booking-calendar-loading">
                  <CircularProgress color="inherit" size={50} />
                  <span className="booking-calendar-loading-text">
                    <FormattedMessage
                      id="admin.dashboard.booking.calendar.loading"
                      defaultMessage={
                        'Prenotazioni della giornata in caricamento'
                      }
                    />
                  </span>
                </div>
              ) : (
                <div className="calendar-content">
                  {!_.isEmpty(dashboardData.currentDayReservations) ? (
                    dashboardData.currentDayReservations.map(reservation => (
                      <div
                        key={reservation.id}
                        className="dashboard-reservation-card"
                        onClick={() =>
                          navigate(
                            FE_ROUTES.RESERVATION_DETAILS +
                              '/' +
                              reservation.id +
                              '/view',
                          )
                        }
                      >
                        <span className="dashboard-reservation-card-date">
                          <span className="delivery-date">
                            {reservation.bookingNumber}
                          </span>
                          <span className="delivery-timeslot">
                            {reservation.timeSlot === 'morning' ? (
                              <FormattedMessage
                                id="supplierDashboard.currentWeek.timeslot.morning"
                                defaultMessage={'MATTINA (08:00 - 12:00)'}
                              />
                            ) : reservation.timeSlot === 'afternoon' ? (
                              <FormattedMessage
                                id="supplierDashboard.currentWeek.timeslot.afternoon"
                                defaultMessage={'POMERIGGIO (14:00 - 18:00)'}
                              />
                            ) : null}
                          </span>
                        </span>
                        <span className="dashboard-reservation-card-bookingNumber">
                          {reservation.contact}
                        </span>
                        <span className="dashboard-reservation-card-vehcilePlate">
                          <SvgIcon
                            icon={ICONS.TOW_TRUCK}
                            color={COLORS.SECONDARY}
                            width={50}
                            height={22}
                          />
                          {reservation.vehiclePlate}
                        </span>
                      </div>
                    ))
                  ) : (
                    <EmptyReservations
                      iconSrc={calendarAdmin}
                      subtitleText={intl.formatMessage({
                        defaultMessage:
                          'Troverai l’elenco di tutte le prenotazioni nella sezione prenotazioni',
                        id: 'admin.dashboard.empty.reservations.subtitle',
                      })}
                      title={intl.formatMessage({
                        defaultMessage:
                          'Per oggi non sono previste prenotazioni',
                        id: 'admin.dashboard.empty.reservations',
                      })}
                    />
                  )}
                </div>
              )}
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default AdminDashboard;
